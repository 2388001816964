<template>
  <div class="xygk">
      <router-view />
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
@maxw:128rem;
.xygk{
  width: 100%;
  height: 100%;
}
</style>